import React from "react"
import "twin.macro"
import HTMLRenderer from "react-html-renderer"
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

export function FeaturedWork({ primary }) {
  const data = useStaticQuery(graphql`
    query FeaturedSite {
      allPrismicWebsites(
        limit: 1
        filter: { data: { featured: { eq: true } } }
      ) {
        totalCount
        nodes {
          data {
            goal_id
            copy {
              html
            }
            link {
              url
            }
            title {
              html
            }
            image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)

  let { data: siteData } = data?.allPrismicWebsites?.nodes[0]
  return (
    <section tw={"py-16 relative"}>
      <div tw={"bg-red-400 h-2/4 w-full absolute bottom-0"} />
      <div tw={"container relative"}>
        <div tw={"lg:w-1/2 mx-auto text-center"}>
          <HTMLRenderer
            html={primary.title.html}
            components={{
              h2: props => (
                <h2
                  tw={
                    " text-4xl md:text-5xl text-red-500 font-sans font-bold leading-none lg:leading-tight mb-8"
                  }
                  {...props}
                />
              ),
            }}
          />
          {console.log(primary)}
          <HTMLRenderer
            html={primary.copy.html}
            components={{
              p: props => (
                <p tw={"text-lg font-sans text-gray-700 pb-8"} {...props} />
              ),
              a: ({ href, ...props }) => (
                <>
                  <Link to={href} {...props} />
                </>
              ),
            }}
          />
        </div>
        <div tw={"lg:w-1/2 mx-auto block"}>
          <div>
            <div tw={"rounded-xl shadow-2xl z-20  bg-white"}>
              <Image fluid={siteData.image.fluid} />

              <div tw={"py-6 px-8 h-full"}>
                <HTMLRenderer
                  html={siteData.title.html}
                  components={{
                    h3: props => (
                      <h3
                        tw={
                          " text-2xl md:text-3xl text-gray-600 font-sans  font-bold leading-none lg:leading-tight py-8"
                        }
                        {...props}
                      />
                    ),
                  }}
                />
                {siteData.copy && (
                  <HTMLRenderer
                    html={siteData.copy.html}
                    components={{
                      p: props => (
                        <p
                          tw={"text-lg font-sans block text-gray-500 pb-8"}
                          {...props}
                        />
                      ),
                    }}
                  />
                )}
                {siteData.link.url && (
                  <a
                    tw="py-4 px-10 uppercase bg-green-300 hover:bg-green-400 inline-block text-green-700 mb-4 font-semibold  font-sans tracking-wider"
                    href={siteData.link.url}
                    onClick={() =>
                      siteData.goal_id &&
                      window.fathom.trackGoal(siteData.goal_id, 0)
                    }
                  >
                    View site
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
