import React from "react"
import Logo from "../components/Logo"
import "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import {
  faDev,
  faTwitter,
  faInstagram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Footer() {
  let SocialIcons = new Map([
    ["Dev.to", faDev],
    ["Github", faGithub],
    ["Twitter", faTwitter],
    ["Instagram", faInstagram],
  ])
  const {
    prismicFooter: { data },
  } = useStaticQuery(graphql`
    query Footer {
      prismicFooter {
        data {
          social_header {
            text
          }
          social_links {
            icon_name
            link {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <footer tw={"bg-white"}>
      <section tw={"container py-12"}>
        <div tw={"flex lg:flex-row flex-col justify-between"}>
          <Logo tw={"w-24 h-auto py-4 mb-12 lg:mb-0"} />
          <div>
            <p tw={"text-lg font-bold font-sans text-gray-700 mb-3"}>
              {data.social_header.text}
            </p>
            {data.social_links.map(social => (
              <a href={social.link?.url}>
                <FontAwesomeIcon
                  icon={SocialIcons.get(social.icon_name)}
                  size={"2x"}
                  tw={"mr-4 text-gray-700"}
                />
              </a>
            ))}
          </div>
        </div>
      </section>
    </footer>
  )
}
