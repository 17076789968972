import React from "react"
import { MarketingHero } from "../Slices/MarketingHero"
import { ContentBlock } from "../Slices/ContentBlock"
import { SlimHero } from "../Slices/SlimHero"
import { ContactBlock } from "../Slices/ContactBlock"
import { FeaturedWork } from "../Slices/FeaturedWork"

export function SliceBuilder(props) {
  const { body } = props
  return body.map(slice => {
    if (slice.__typename === "PrismicPageBodyMarketingHero")
      return <MarketingHero {...slice} key={slice.id} />
    if (slice.__typename === "PrismicPageBodyBackgroundContentBlock")
      return <ContentBlock {...slice} key={slice.id} />
    if (slice.__typename === "PrismicPageBodySlimHero")
      return <SlimHero {...slice} key={slice.id} />
    if (slice.__typename === "PrismicPageBodyContactBlock")
      return <ContactBlock {...slice} key={slice.id} />
    if (slice.__typename === "PrismicPageBodyFeaturedBlock")
      return <FeaturedWork {...slice} key={slice.id} />
    return <p>{`to do ${slice.__typename}`}</p>
  })
}
