import React from "react"
import Logo from "./Logo"
import { Link } from "gatsby"
import "twin.macro"

export let Header = () => (
  <div tw={"flex justify-between px-8 py-4 items-center relative"}>
    <Logo tw={"w-20  z-20 h-16"} />
    <Link to={"/contact"}>
      <div tw={"text-white font-sans bg-dark-blue py-3 px-6"}>Contact</div>
    </Link>
  </div>
)

export default Header
