/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default function SEO({ children, location, title, image, description }) {
  const { site } = useStaticQuery(graphql`
    query siteData {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <title>{title}</title>
      <meta name={"description"} content={description} />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || "/logo.svg"} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        propery="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={description} key="ogdesc" />
    </Helmet>
  )
}
