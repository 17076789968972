import React from "react"
import tw from "twin.macro"
import { Elements, RichText } from "prismic-reactjs"
import Header from "../components/header"

const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key })
}

const Serialiser = (type, element, context, children, key) => {
  let props = {}

  switch (type) {
    case Elements.heading1:
      return (
        <h1
          tw={
            "text-4xl lg:text-6xl text-gray-900 w-full py-6 text-center font-sans z-10 font-bold"
          }
          {...propsWithUniqueKey(props, key)}
        >
          {children}
        </h1>
      )

    case Elements.paragraph:
      return (
        <p
          tw={"text-lg text-center font-sans w-full text-gray-600"}
          {...propsWithUniqueKey(props, key)}
        >
          {children}
        </p>
      )
  }
  return null
}

export function SlimHero({ primary }) {
  console.log({ primary })
  return (
    <section
      tw="mx-4 lg:mx-6 mt-6 rounded-2xl"
      css={[
        primary.background && `background-color: ${primary.background}`,
        !primary.background && tw`bg-blue-50`,
      ]}
    >
      <Header />
      <div
        tw={"container flex justify-center items-center flex-col pb-32 mb-6"}
      >
        <div
          css={`
            .under {
              position: relative;
              z-index: 10;
              line-height: 0;
              background-repeat: no-repeat;
              background-size: 100% 10px;
              background-position: 0 88%;
              background-image: linear-gradient(
                120deg,
                ${primary?.decoration_colour} 0%,
                ${primary?.decoration_colour} 100%
              );
            }
          `}
        >
          <RichText render={primary.title.raw} htmlSerializer={Serialiser} />
        </div>
        <RichText render={primary.copy.raw} htmlSerializer={Serialiser} />
      </div>
    </section>
  )
}
