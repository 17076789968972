import React from "react"
import tw from "twin.macro"

function generateAvail({ color, message }) {
  return (
    <div tw={"flex items-center"}>
      <div tw={"rounded-full w-3 h-3 mr-3"} css={[color && color]}></div>
      <p tw={"text-gray-700 font-semibold font-sans py-4"}>{message}</p>
    </div>
  )
}

export function ContactBlock(props) {
  const d = new Date()
  const m = d.getMonth()
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  let currentAvailability = new Map([
    [
      "Good",
      {
        color: tw`bg-green-500`,
        message: `We currently have good availability for ${monthNames[m]}`,
      },
    ],
    [
      "Limited",
      {
        color: tw`bg-yellow-500`,
        message: `We currently have limited availability for ${monthNames[m]}. If you're project is urgent, let us know.`,
      },
    ],
    [
      "None",
      {
        color: tw`bg-gray-400`,
        message: `We currently have no availability for ${monthNames[m]}`,
      },
    ],
  ])

  const { primary } = props
  return (
    <section tw={"mx-8 lg:mx-0 -mt-20"}>
      <div
        tw={"container bg-white rounded-lg mb-12 py-6  px-6 lg:px-24 shadow-xl"}
      >
        {generateAvail(currentAvailability.get(primary.availability))}
        <hr />
        <form
          name={"contact"}
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          tw={"lg:grid grid-cols-2 gap-y-2 gap-x-8 py-4"}
        >
          <input type={"hidden"} name={"form-name"} value={"contact"} />
          <p tw={"hidden"}>
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <p tw={"mb-4"}>
            <label tw={"font-sans text-gray-800 text-xl"}>
              Your first name
              <input
                type={"text"}
                name={"name"}
                tw={
                  "bg-gray-100 font-sans w-full mt-2 py-2 px-6 focus:ring-2 focus:ring-indigo-500"
                }
              />
            </label>
          </p>
          <p tw={"mb-4"}>
            <label tw={"font-sans text-gray-800 text-xl"}>
              Your email address
              <input
                type={"email"}
                name={"email"}
                tw={
                  "bg-gray-100 font-sans w-full mt-2 py-2 px-6 focus:ring-2 focus:ring-indigo-500"
                }
              />
            </label>
          </p>
          <div tw={"col-span-full mb-6"}>
            <p>
              <label tw={"font-sans text-gray-800 text-xl"}>
                Some info about your project.
                <span
                  tw={"text-sm  font-medium block text-gray-500 tracking-wide"}
                >
                  (If you just have the crib notes, that's fine! We'll help you
                  when we talk about your project)
                </span>
                <textarea
                  name="project"
                  wrap={"soft"}
                  cols={4}
                  rows={12}
                  tw={
                    "bg-gray-100 font-sans w-full mt-2 resize-y h-24 focus:ring-2 focus:ring-indigo-500 py-2 px-2"
                  }
                />
              </label>
            </p>
          </div>
          <div data-netlify-recaptcha="true" tw={"col-span-full"}></div>
          <button
            tw={"bg-indigo-500 text-white py-2 px-6 font-sans shadow"}
            type={"submit"}
          >
            Submit
          </button>
        </form>
        <p tw={"text-sm  font-medium block text-gray-500 tracking-wide"}>
          We will use the data collected in this form to contact you, no spam,
          no selling your data, no funny business - scouts honor.
        </p>
      </div>
    </section>
  )
}
