import React from "react"
import "twin.macro"
import Img from "gatsby-image"
import { Link } from "gatsby"
import HTMLRenderer from "react-html-renderer"

export let ContentBlock = ({ primary }) => {
  return (
    <section tw={"relative py-24"}>
      <div
        tw={"absolute bottom-0 left-0 h-3/6 lg:h-4/6 bg-purple-200 w-full z-0"}
      />
      <div
        tw={"lg:grid"}
        css={`
          @media screen and (min-width: 1024px) {
            grid-template-columns: 1fr max-content 1fr;
            grid-template-areas: "left content right";
          }
        `}
      >
        <div
          tw={"container lg:p-0 flex flex-col md:flex-row h-full relative"}
          css={`
            grid-area: content;
          `}
        >
          <div tw={"lg:w-6/12 w-full lg:-mr-16  z-20 relative"}>
            <div
              tw={"relative"}
              css={`
                @media screen and (min-width: 768px) {
                  ::after {
                    content: "";
                    width: 100px;
                    height: 100px;
                    bottom: -5%;
                    left: -5%;
                    z-index: 10;
                    position: absolute;
                    background: ${primary.square_colour};
                  }
                }
              `}
            >
              <Img fluid={primary.image.fluid} tw={"pb-8 z-20"} />
            </div>
          </div>
          <div
            tw={"bg-white pb-12 px-8 pt-12 lg:pl-32 relative"}
            css={`
              ::after {
                content: "";
                background: white;
                top: 0;
                right: -50%;
                width: 40%;
                height: 100%;
                position: absolute;
              }
            `}
          >
            <div
              tw={
                "bg-green-100 text-green-500 inline-block uppercase text-base font-sans font-bold p-3 mb-6"
              }
            >
              {primary.eyebrow.text}
            </div>

            <div
              css={`
                .under {
                  position: relative;
                  z-index: 10;
                  line-height: 0;
                  background-repeat: no-repeat;
                  background-size: 100% 10px;
                  background-position: 0 95%;
                  background-image: linear-gradient(
                    120deg,
                    ${primary.decoration_colour} 0%,
                    ${primary.decoration_colour} 100%
                  );
                }
              `}
            >
              <HTMLRenderer
                html={primary.title.html}
                components={{
                  h2: props => (
                    <h2
                      tw={
                        " text-3xl md:text-4xl text-gray-800 font-sans lg:w-7/12 font-bold leading-none lg:leading-tight mb-8"
                      }
                      {...props}
                    />
                  ),
                }}
              />
            </div>
            <HTMLRenderer
              html={primary.copy.html}
              components={{
                h4: props => (
                  <h4
                    tw={"text-xl font-sans font-bold text-gray-800 pb-4"}
                    {...props}
                  />
                ),
                p: props => (
                  <p tw={"text-lg font-sans  text-gray-700 pb-8"} {...props} />
                ),
                a: ({ href, ...props }) => (
                  <>
                    <Link
                      to={href}
                      {...props}
                      tw={"text-indigo-500 hover:text-indigo-600"}
                    />
                  </>
                ),
              }}
            />
          </div>
        </div>
        <div
          tw={"h-full bg-white hidden lg:block relative"}
          css={`
            grid-area: right;
          `}
        />
      </div>
    </section>
  )
}
