import React from "react"
import { graphql } from "gatsby"
import { SliceBuilder } from "../helpers/SliceBuilder"
import Layout from "../components/layout"
import { withPreview } from "gatsby-source-prismic"

let Page = ({
  data: {
    prismicPage: { data },
  },
}) => {
  let meta = {
    title: data.meta_title.text,
    description: data.meta_copy.text,
  }
  return (
    <Layout meta={meta}>
      <SliceBuilder body={data.body} />
    </Layout>
  )
}

export default withPreview(Page)

export const query = graphql`
  query Page($slug: String) {
    prismicPage(uid: { eq: $slug }) {
      data {
        meta_copy {
          text
        }
        meta_title {
          text
        }
        body {
          ... on PrismicPageBodyContactBlock {
            id
            slice_type
            primary {
              availability
            }
          }
          ... on PrismicPageBodyBackgroundContentBlock {
            id
            slice_type
            primary {
              decoration_colour
              square_colour
              copy {
                html
              }
              eyebrow {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              title {
                html
              }
            }
          }
          ... on PrismicPageBodySlimHero {
            id
            slice_type
            primary {
              decoration_colour
              background
              title {
                raw
              }
              copy {
                raw
              }
            }
          }
          ... on PrismicPageBodyFeaturedBlock {
            id
            slice_type
            primary {
              title {
                html
              }
              copy {
                html
              }
            }
          }
          ... on PrismicPageBodyMarketingHero {
            id
            items {
              label
              link_type
              link {
                document {
                  ... on PrismicPage {
                    uid
                  }
                }
              }
            }
            primary {
              background_colour
              decoration_colour
              title {
                raw
              }
              copy {
                raw
              }
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
