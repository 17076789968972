import React from "react"
import { RichText, Elements } from "prismic-reactjs"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "twin.macro"
import Header from "../components/header"

const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key })
}

const Serialiser = (type, element, context, children, key) => {
  let props = {}

  switch (type) {
    case Elements.heading1:
      return (
        <h1
          tw={"text-5xl lg:text-6xl text-gray-900 font-sans z-10 font-bold"}
          {...propsWithUniqueKey(props, key)}
        >
          {children}
        </h1>
      )

    case Elements.paragraph:
      return (
        <p
          tw={"text-lg font-sans text-gray-600"}
          {...propsWithUniqueKey(props, key)}
        >
          {children}
        </p>
      )
  }
  return null
}

export function MarketingHero(props) {
  let { primary, items } = props

  return (
    <div tw={"relative bg-blue-50 mx-4 lg:mx-6 mt-6 rounded-2xl"}>
      <Header />
      <section tw={"relative"}>
        <div tw={"container mx-auto flex flex-col lg:flex-row"}>
          <div tw={"h-3/6 lg:h-auto lg:w-5/12 w-full py-28"}>
            <div
              css={`
                .under {
                  position: relative;
                  z-index: 10;
                  line-height: 0;
                  background-repeat: no-repeat;
                  background-size: 100% 10px;
                  background-position: 0 88%;
                  background-image: linear-gradient(
                    120deg,
                    ${primary.decoration_colour} 0%,
                    ${primary.decoration_colour} 100%
                  );
                }
              `}
            >
              <RichText
                render={primary.title.raw}
                htmlSerializer={Serialiser}
              />
            </div>
            <div tw={"py-16"}>
              <RichText render={primary.copy.raw} htmlSerializer={Serialiser} />
            </div>
            {items?.map((cta, i) => (
              <Link to={cta?.link?.document?.uid}>
                <p
                  tw={
                    "bg-indigo-600 hover:bg-indigo-500 text-white font-sans py-4 px-8 shadow-lg inline-block cursor-pointer"
                  }
                >
                  {cta.label}
                </p>
              </Link>
            ))}
          </div>
          {primary.image && (
            <div tw={"w-full h-3/6 lg:h-auto lg:w-7/12"}>
              <Img fluid={primary.image.fluid} />
            </div>
          )}
        </div>
      </section>
    </div>
  )
}
