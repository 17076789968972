/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { GlobalStyles } from "twin.macro"
import { createGlobalStyle } from "styled-components"
import Footer from "./Footer"
import SEO from "./seo"

let Resets = createGlobalStyle`
body {
margin: 0;
}`

const Layout = ({ children, meta }) => {
  return (
    <>
      <GlobalStyles />
      <Resets />
      <SEO {...meta} />
      <div tw={"overflow-hidden"}>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
